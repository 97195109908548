<template>

    <div class="container-sm section-default">
        <div class="row">
            <h1 class="heading2">Como funciona para os candidatos</h1>
            <p>Se você é um profissional em busca de vagas e quer descobrir como funciona a SFIT, vai se surpreender com essa nova forma de produrar emprego. Veja como podemos te ajudar a conquistar seu lugar no mercado:</p>
        </div>          
    </div>
    <div class="bg-light">
        <div class="row">
            <div class="container-sm section-default ">
                <section class="text-left container">
                    <h3 class="heading3 heading-site-21 text-start">Você cadastra um perfil e recebe diversas <span class="green-text">propostas</span></h3>
                </section>
                <div class="container ">
                    <div class="row">
                        <div class="col-md-3 mb-md-0 mb-2">
                            <div class="card d-flex flex-column align-items-center justify-content-center">
                                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                                    <div class="img-container rounded-circle"> <img src="/img/1-Azul.png" alt="" class="rounded-circle"> </div>
                                    <div class="h4">Crie seu perfil em poucos cliques</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-md-0 mb-2">
                            <div class="card d-flex flex-column align-items-center justify-content-center">
                                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                                    <div class="img-container rounded-circle"> <img src="/img/2-Azul.png" alt="" class="rounded-circle"> </div>
                                    <div class="h4">Marque suas habilidades</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-md-0 mb-2">
                            <div class="card d-flex flex-column align-items-center justify-content-center">
                                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                                    <div class="img-container rounded-circle"> <img src="/img/4-Azul.png" alt="" class="rounded-circle"> </div>
                                    <div class="h4">Receba convites para entrevistae</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-md-0 mb-2">
                            <div class="card d-flex flex-column align-items-center justify-content-center">
                                <div class="inner-content d-flex flex-column align-items-center justify-content-center">
                                    <div class="img-container rounded-circle"> <img src="/img/3-Azul.png" alt="" class="rounded-circle"> </div>
                                    <div class="h4">Seja contratado(a)!</div>
                                </div>
                            </div>
                        </div>        
                    </div>
                </div>  
            </div>
        </div>  
    </div>  
    <div class="container-sm section-default">
        <h2 class="heading3" data-v-06256656="">Veja algumas das nossas carreiras</h2>

        <div class="row">
            <div class="col first">
                <h3 class="green-text heading5">Desenvolvimento</h3>
                <ul role="list">
                    <li>Back-end</li>
                    <li>Front-end</li>
                    <li>Full-stack</li>
                    <li>Mobile</li>
                </ul>
            </div>
            <div class="col">
                <h3 class="green-text heading5">TI</h3>
                <ul role="list">
                    <li>Administrador de sistemas</li>
                    <li>Administrador de base de dados</li>
                    <li>DevOps</li>
                    <li>Segurança da Informação</li>
                </ul>
            </div>
            <div class="col">
                <h3 class="green-text heading5">Produto e negócios</h3>
                <ul role="list">
                    <li>Product Management</li>
                    <li>Estratégia</li>
                    <li>Business Development</li>
                    <li>Technical Product Manager</li>
                </ul>
            </div>
        </div>    
        <div class="row">
            <div class="col first">
                <h3 class="green-text heading5">Design</h3>
                <ul role="list">
                    <li>UX Designer</li>
                    <li>UI Designer</li>
                    <li>Product Designer</li>
                    <li>Design Ops</li>
                </ul>
            </div>
            <div class="col">
                <h3 class="green-text heading5">Data Analytics</h3>
                <ul role="list">
                    <li>Business Intelligence</li>
                    <li>Analista de Performance</li>
                    <li>Data Scientist</li>
                    <li>Analista de Dados</li>
                </ul>
            </div>
            <div class="col">
                <h3 class="green-text heading5">Marketing Online</h3>
                <ul role="list">
                    <li>Growth Hacker</li>
                    <li>Conteúdo</li>
                    <li>Performance</li>
                    <li>Social Media</li>
                </ul>
            </div>
        </div>             
    </div>    
  
  
</template>

<script>

</script>

<style scoped>

.heading1 {
    margin-bottom: 10px;
    font-family: Antenna,sans-serif;
    font-size: 58px;
    line-height: 60px;
    font-weight: 400;
}

.heading2 {
    min-height: auto;
    min-width: auto;
    margin-top: 0;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-left: 0;
    color: #273a50;
    font-size: 45px;
    line-height: 47px;
    font-family: Antenna,sans-serif;
    font-weight: 400;
}
.heading3 {
    position: relative;
    z-index: 5;
    margin-top: 10px;
    padding-bottom: 0;
    padding-left: 0;
    font-family: Antenna,sans-serif;
    font-size: 34px;
    line-height: 38px;
    font-weight: 400;
    text-align: left;
}

.heading5 {
    position: relative;
    z-index: 5;
    margin-top: 10px;
    padding-bottom: 0;
    padding-left: 0;
    font-family: Antenna,sans-serif;
    font-size: 24px;
    line-height: 38px;
    font-weight: 400;
    text-align: left;
}

.sub-title {
    font-size: 22px;
    margin: 10px 0px 10px;
}

.home-button {
  position: relative;
  display: inline-block;
  width: auto;
  min-height: 40px;
  min-width: 150px;
  margin: 10px 10px 0px 0px;
  padding: 15px 30px;
  clear: left;
  border-radius: 4px;
  background-color: #5F78EA;
  font-family: Antenna, sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  min-width: 258px;
}

.home-button:hover {
  background-color: #4D5FAF;
  color: #fff;
}

a {
    color: #3256a1;
    text-decoration: none;
}



.container .col-md-4 {
    display: flex;
    align-items: center;
    justify-content: center
}

.container .card {
    width: 280px;
    height: 320px;
    overflow: hidden;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    border: none;
    /* box-shadow: 5px 5px 10px #1f1f1fa8, -5px -5px 10px #1f1f1fa8 */
    box-shadow: 1px 1px 8px 0 rgba(39,58,80,.2);
}

.container .card .carreira {
    width: 280px;
    overflow: hidden;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    border: none;
    /* box-shadow: 5px 5px 10px #1f1f1fa8, -5px -5px 10px #1f1f1fa8 */
}

.container .card:hover::before {
    height: 150px
}

.container .card .img-container {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    transition: all 0.3s ease-in-out
}

.container .card:hover .img-container {
    border: 10px solid #5F78EA
}

.container .card .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: all 0.3s ease-in-out
}

.container .card:hover .img-container img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.9)
}

.container .card .h3 {
    margin-bottom: 5px !important;
    font-weight: 600;
    pointer-events: none
}

.container .card .designation {
    font-size: 0.85rem;
    letter-spacing: 2px;
    pointer-events: none
}

.container .card ul {
    background-color: #5F78EA;
    transition: all 0.5s ease-in-out;
    transform: translateY(100px)
}

.container .card:hover ul {
    transform: translateY(40px)
}

.container .card ul li {
    padding: 5px 8px;
    transition: all 0.4s
}

.container .card ul li a {
    color: #FFF
}

.container .card ul li:hover {
    background: #fff
}

.container .card ul li:hover a {
    color: #5F78EA
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
li {
    margin-bottom: 5px;
    font-family: Open Sans,sans-serif;
    color: #273a50;
    font-size: 16px;
    line-height: 24px;
    list-style-type: square;
    display: list-item;
    text-align: start;
}

.first {
    padding-left: 40px;
}
</style>